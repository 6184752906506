import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import { navigateTo } from "gatsby-link";
import Layout from "../components/layout";
import MainBg from "../components/mainBg";
import NewsletterSignup from "../components/newsletterSignup";
import Iframe from "react-iframe";
import CtaButtons from "../components/ctaButtons";

function encode(data) {
  const formData = new FormData();

  for (const key of Object.keys(data)) {
    formData.append(key, data[key]);
  }

  return formData;
}

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleAttachment = (e) => {
    this.setState({ [e.target.name]: e.target.files[0] });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => navigateTo(form.getAttribute("action")))
      .catch((error) => alert(error));
  };

  render() {
    return (
      <Layout location={this.props.location}>
        <Helmet
          htmlAttributes={{ lang: "en" }}
          title="Contact Us | Lewis Mohr Insurance Agency"
          meta={[
            {
              name: "description",
              content:
                "If you have a general question about insurance for your business or family, please fill out the form to get in touch with one of our Baton Rouge, Louisiana based independent insurance agents.",
            },
          ]}
        />
        <MainBg mainBg={this.props.data.mainBg} />
        <div className="site-body page-content">
          <h1>Contact Us</h1>
          <p>
            Have a general question? Please fill out the form below to get in
            touch with one of our Baton Rouge, Louisiana based independent
            insurance agents.. If you would like to file a claim or get
            insurance please click one of the buttons below.
          </p>
          <CtaButtons />
          <br />
          <div className="grid grid-gutters-lg">
            <div className="grid-cell u-full u-med-1of2 u-large-1of2">
              <form
                name="Lewis Mohr Contact Form"
                method="post"
                action="/thanks/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={this.handleSubmit}
              >
                <div className="grid grid-gutters-sides grid-full">
                  {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                  <input type="hidden" name="form-name" value="file-upload" />
                  <p hidden>
                    <label>
                      Don’t fill this out:{" "}
                      <input name="bot-field" onChange={this.handleChange} />
                    </label>
                  </p>
                  <div className="grid-cell">
                    <label>
                      First Name
                      <sup>*</sup>
                      <input
                        type="text"
                        id="firstNameField"
                        name="firstName"
                        placeholder=""
                        onChange={this.handleChange}
                        required
                      />
                    </label>
                  </div>
                  <div className="grid-cell">
                    <label>
                      Last Name
                      <sup>*</sup>
                      <input
                        type="text"
                        id="lastNameField"
                        name="lastName"
                        placeholder=""
                        onChange={this.handleChange}
                        required
                      />
                    </label>
                  </div>
                  <div className="grid-cell">
                    <label>
                      Email
                      <sup>*</sup>
                      <input
                        type="email"
                        id="emailField"
                        name="email"
                        placeholder=""
                        onChange={this.handleChange}
                        required
                      />
                    </label>
                  </div>
                  <div className="grid-cell">
                    <label>
                      Phone
                      <sup>*</sup>
                      <input
                        type="phone"
                        id="phoneField"
                        name="phone"
                        placeholder=""
                        pattern=".{10,}"
                        minLength="10"
                        onChange={this.handleChange}
                        required
                      />
                    </label>
                  </div>
                  <div className="grid-cell">
                    <label>
                      Company Name (Optional)
                      <input
                        type="text"
                        id="companyName"
                        name="Company Name"
                        placeholder=""
                        onChange={this.handleChange}
                      />
                    </label>
                  </div>
                  <div className="grid-cell">
                    <label>
                      Type of Insurance
                      <sup>*</sup>
                      <br />
                      <input
                        type="radio"
                        name="insuranceType"
                        value="Personal"
                        onChange={this.handleChange}
                        required
                      />
                      <span className="radio-label">Personal</span>
                      <input
                        type="radio"
                        name="insuranceType"
                        value="Business"
                        onChange={this.handleChange}
                        required
                      />
                      <span className="radio-label">Business</span>
                    </label>
                  </div>
                  <div className="grid-cell u-full">
                    <label>
                      Message
                      <sup>*</sup>
                      <textarea
                        type="message"
                        id="messageField"
                        name="Message"
                        placeholder=""
                        pattern=".{10,}"
                        minLength="10"
                        onChange={this.handleChange}
                        required
                      />
                    </label>
                  </div>
                  <div className="grid-cell u-full">
                    <div className="disclaimer">
                      Note: No coverage may be bound or altered by email or
                      voice mail. You must speak with a representative.
                    </div>
                  </div>
                  <div className="grid-cell u-full">
                    <button type="submit">Submit Form</button>
                  </div>
                </div>
              </form>
            </div>
            <div className="grid-cell u-full u-med-1of2 u-large-1of2">
              <p>
                <strong>Phone</strong>
                <br />
                <a href="tel:2252931086">225-293-1086</a>
              </p>
              <p>
                <strong>Address</strong>
                <br />{" "}
                <a
                  href="https://goo.gl/maps/pNqYKsofVs22"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  11051 Coursey Blvd.
                  <br /> Baton Rouge, LA 70816
                </a>
              </p>
              <Iframe
                url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d17622.6845371012!2d-91.07033648938716!3d30.416714717612674!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8626bb4cb424996b%3A0x988b1b0aad8ee041!2sLewis+Mohr+Real+Estate+%26+Insurance+Agency%2C+LLC!5e0!3m2!1sen!2sus!4v1450366648289"
                width="400px"
                height="300px"
                frameborder="0"
                allowfullscreen
                id="myId"
                className="iframe"
                display="initial"
                position="relative"
                title="Google Maps of Lewis Mohr's Office"
              />
            </div>
          </div>
        </div>
        <NewsletterSignup />
      </Layout>
    );
  }
}

export default Contact;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    mainBg: file(relativePath: { eq: "contact-bg.jpg" }) {
      ...mainBg
    }
    callToActionBg: file(relativePath: { eq: "bg-cta-28.jpg" }) {
      ...mainBg
    }
  }
`;
